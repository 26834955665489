// extracted by mini-css-extract-plugin
export var content = "index-module--content--nLV7a";
export var head = "index-module--head--z07bR";
export var icon = "index-module--icon--Ud+js";
export var stepCardContainer = "index-module--stepCardContainer--cBvKV";
export var stepCardContainerMb = "index-module--stepCardContainerMb--YdfNd";
export var stepItem = "index-module--stepItem--0Zg0l";
export var stepItemDesc = "index-module--stepItemDesc--eFdFL";
export var stepItemIcon = "index-module--stepItemIcon--159GX";
export var stepItemImg = "index-module--stepItemImg--LhnXg";
export var stepItemTitle = "index-module--stepItemTitle--isbkt";
export var title = "index-module--title--26fex";