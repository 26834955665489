// extracted by mini-css-extract-plugin
export var JOERoot = "index-module--JOERoot--Oo+m2";
export var bWarrper = "index-module--bWarrper--m7i9G";
export var banner = "index-module--banner--ZeBqy";
export var bannerBtnsClass = "index-module--bannerBtnsClass--jcc+3";
export var bannerDesc = "index-module--bannerDesc--fG5q-";
export var bannerTitle = "index-module--bannerTitle--ORC-r";
export var bannerWrapper = "index-module--bannerWrapper--E2Q8j";
export var carousel = "index-module--carousel--8MoJY";
export var carouselWrapper = "index-module--carouselWrapper--wvoz0";
export var enTitle = "index-module--enTitle--Oi7GQ";
export var gCard = "index-module--gCard--1IPob";
export var itemContenWrapperClass = "index-module--itemContenWrapperClass--R5tpO";
export var linkIconClassName = "index-module--linkIconClassName--b4lpg";
export var logo_wall_en = "index-module--logo_wall_en--G0xly";
export var notebook = "index-module--notebook--rzpOK";
export var pageBottomCardDesc = "index-module--pageBottomCardDesc--DKfnV";
export var partContent = "index-module--partContent--HUHFT";
export var tabItemClass = "index-module--tabItemClass--Dx2kC";
export var tabsClass = "index-module--tabsClass--VbHnw";
export var wWarrper = "index-module--wWarrper--bjyNz";