import React, { CSSProperties, useState, useEffect } from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import { Button } from 'components/index'
import { ButtonGroup } from 'components/Base/Button'
import icon from './img/icon.png'
import hoverIcon from './img/hoverIcon.png'

interface ExtendedCSSProperties extends CSSProperties {
  /** 背景图片 */
  '--step-bg'?: string
  /**步骤图标 */
  '--step-icon'?: string
  /** 移动端背景图片 */
  '--step-mbBg'?: string
  /** 进度条的颜色 */
  '--step-border-color'?: string
}

interface ShowCardProps {
  /**数据数组 */
  stepArr: any
  /**按钮内容 */
  buttons?: {
    /**按钮文案 */
    text: string
    /**按钮跳转链接 */
    href: string
    ghost?: boolean
  }[]
}

const ShowCard: React.FC<ShowCardProps> = (props) => {
  const { stepArr, buttons } = props

  //判断是否是移动端
  const isMb = useIsWindowWidthSmaller()

  return isMb ? (
    <div className={styles.stepCardContainerMb}>
      <div className={styles.content}>
        {stepArr.map((item) => {
          const { title, desc, mbImg } = item
          const step: ExtendedCSSProperties = {
            '--step-bg': `url(${mbImg.publicURL ?? mbImg})`,
          }
          return (
            <div className={styles.stepItem} style={step}>
              <div className={styles.head}>
                <div className={styles.icon}></div>
                <div className={styles.stepItemImg}></div>
                <div className={styles.title}>{title}</div>
              </div>
              {desc}
            </div>
          )
        })}
      </div>
      <div className="flex justify-center mt-[3rem] ">
        <ButtonGroup>
          {buttons &&
            buttons.map((item, index) => {
              return (
                <Button btnType="primary" key={index} href={item.href} ghost={item.ghost} target="_blank">
                  {item.text}
                </Button>
              )
            })}
        </ButtonGroup>
      </div>
    </div>
  ) : (
    <div className={classnames(styles.stepCardContainer)}>
      <div className={styles.content}>
        {stepArr.map((item, index) => {
          const { title, desc, img } = item
          const step: ExtendedCSSProperties = {
            '--step-bg': `url(${img.publicURL ?? img})`,
          }
          return (
            <div className={styles.stepItem} key={desc} style={step}>
              <h3 className={styles.stepItemTitle}>{title}</h3>
              <div className={styles.stepItemIcon}></div>
              <div className={styles.stepItemImg}></div>
              <div className={styles.stepItemDesc}>{desc}</div>
            </div>
          )
        })}
      </div>
      <div className="flex justify-center mt-[60px]">
        <ButtonGroup>
          {buttons &&
            buttons.map((item, index) => {
              return (
                <Button btnType="primary" key={index} href={item.href} ghost={item.ghost} target="_blank">
                  {item.text}
                </Button>
              )
            })}
        </ButtonGroup>
      </div>
    </div>
  )
}

export default ShowCard
